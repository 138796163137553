import './App.css';
import { Button, Page, Textarea } from '@mojo-ui/react';
import { Dropdown, Picker, SpinLoading } from 'antd-mobile';
import { useCallback, useRef, useState, useEffect } from 'react';
import AudioH5Player from 'react-h5-audio-player';
import { digiforceTextToSpeech, getDigiforceTextToSpeech } from './service';
import { useTranslation } from 'react-i18next';


const langData = [
  [
    { value: 'vi', label: 'Tiếng Việt' },
    { value: 'en', label: 'English' },
  ],
];

// audio config
function AudioPlayer(src) {
  return (
    <AudioH5Player
      autoPlay={false}
      src={src.src}
      autoPlayAfterSrcChange={false}
      showJumpControls={false}
      showFilledProgress={false}
      customVolumeControls={[]}
      customAdditionalControls={[]}
      layout='horizontal'
    />
  );
}

// app config
function App() {
  const ref = useRef();
  const refLang = useRef();
  const isMobile = useCallback(() => {
    return window.innerWidth < 500;
  }, []);

  const [visibleVoide, setVisibleVoide] = useState(false);
  const [visibleLang, setVisibleLang] = useState(false);
  const isMobileDevice = isMobile();
  const [voice, setVoice] = useState();
  const [lang, setLang] = useState();
  const [textChange, setTextChange] = useState('');
  const [srcAudio, setSrcAudio] = useState(false);
  const [params, setParams] = useState({});
  const [srcFile, setSrcFile] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [voiceData, setVoiceData] = useState([[{}]]);
  const {t, i18n} = useTranslation();
  const [langeState, setLangState] = useState('')

  const downloadAudio = () => {
    const element = document.createElement('a');
    element.href = `${srcFile}?download=true`;
    element.download = 'audio.wav';
    element.click();
  };

  const onSubmit = async (value) => {
    setDisableButton(true);
    setIsLoading(true);
    setSrcAudio(false);
    if (!value.lang) {
      value.lang = {
        value: 'vi',
      };
    }
    if (!value.voice) {
      value.voice = {
        value: 'thuy_pham',
      };
    }
    try {
      const voiceObject = voiceData[0].find(
        (obj) => obj.value === value.voice?.value
      );
      let gender = voiceObject.gender.split('_')[0];
      gender = gender.charAt(0).toUpperCase() + gender.slice(1);
      let voice = voiceObject.value;

      const valueData = {
        lang: value?.lang?.value,
        voice: voice,
        gender: gender,
        text: value.textChange,
      };

      const dataResult = await digiforceTextToSpeech(
        valueData,
        params?.data?.token
      );

      if (dataResult) {
        try {
          const recordId = dataResult?._id ?? '';
          const audioRecord = await getDigiforceTextToSpeech(
            recordId,
            params?.data?.token
          );
          if (audioRecord) {
            try {
              const audioRecordfile = audioRecord?.file ?? '';
              setSrcFile(
                `https://miniapp-proxy.mojo.vn/api/files/files/${audioRecordfile}`
              );
              setSrcAudio(true);
            } catch (error) {
              throw new Error('Can not set source audio', error);
            }
          }
        } catch (err) {
          throw new Error('Can not get record', err);
        }
      }
      setDisableButton(false);
      setIsLoading(false);
    } catch (error) {
      throw new Error('Fail to generate text to speech', error);
    }
  };

  const onSelectLangToGetVoices = (data) => {
    setLang(data);
    return lang;
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.href);
    const token = searchParams.get('token');
    const language = searchParams.get('lang');
    setParams({ data: { token } });
    if (!!textChange) {
      setDisableButton(false);
    }
    if (lang) {
      new Promise((res, rej) => {
        setTimeout(res, 500);
      }).then(() => {
        const dataVi = [
          [
            {
              gender: 'female_1',
              label: 'Thuý Phạm',
              value: 'thuy_pham',
            },
            {
              gender: 'female_2',
              label: 'Thắm Mai',
              value: 'tham_mai',
            },
            {
              gender: 'female_3',
              label: 'Thảo Nguyễn',
              value: 'thao_nguyen',
            },
            {
              gender: 'female_4',
              label: 'Tiên Trần',
              value: 'tien_tran',
            },
            {
              gender: 'male_1',
              label: 'Ân Nguyễn',
              value: 'an_nguyen',
            },
            {
              gender: 'male_2',
              label: 'Thạch Trương',
              value: 'thach_truong',
            },
            {
              gender: 'male_3',
              label: 'Khương Nguyễn',
              value: 'khuong_nguyen',
            },
            {
              gender: 'male_4',
              label: 'Lâm Trần',
              value: 'lam_tran',
            },
          ],
        ];
        const dataEn = [
          [
            {
              gender: 'female_1',
              label: 'Thuy Phạm',
              value: 'thuy_pham',
            },
            {
              gender: 'female_2',
              label: 'Tham Mai',
              value: 'tham_mai',
            },
            {
              gender: 'female_3',
              label: 'Thao Nguyen',
              value: 'thao_nguyen',
            },
            {
              gender: 'female_4',
              label: 'Tien Tran',
              value: 'tien_tran',
            },
            {
              gender: 'male_1',
              label: 'An Nguyen',
              value: 'an_nguyen',
            },
            {
              gender: 'male_2',
              label: 'Thach Truong',
              value: 'thach_truong',
            },
            {
              gender: 'male_3',
              label: 'Khuong Nguyen',
              value: 'khuong_nguyen',
            },
            {
              gender: 'male_4',
              label: 'Lam Tran',
              value: 'lam_tran',
            },
          ],
        ];
        if (lang.value === 'vi') {
          setVoiceData(dataVi);
        } else {
          setVoiceData(dataEn);
        }
      });
    }
    if (language) {
      setLangState(language)
      i18n.changeLanguage(langeState);
    }
    
  }, [textChange, setDisableButton, lang, i18n, langeState]);

  return (
    <Page
      config={{ appCode: 'token' }}
      onLoaded={setParams}
      content={
        <div className='page-content'>
          <div className='handle-content'>
            <div>
              <Textarea
                rows={5}
                placeholder={t('content.placeholder')}
                id='myTextarea'
                onBlur={(t) => {
                  setTextChange(t.target.value);
                }}
                required
              ></Textarea>
              <div>
                {isMobileDevice ? (
                  <>
                    <Button
                      className='picker-btn'
                      onClick={() => {
                        setVisibleLang(true);
                      }}
                    >
                      {lang?.label ?? t('content.language')}
                    </Button>
                    <Picker
                      columns={langData}
                      visible={visibleLang}
                      onClose={() => {
                        setVisibleLang(false);
                      }}
                      onConfirm={(_value, data) => {
                        onSelectLangToGetVoices(data.items?.[0]);
                      }}
                      confirmText='OK'
                      cancelText='Cancel'
                    />
                    <Button
                      className='picker-btn'
                      onClick={() => {
                        setVisibleVoide(true);
                      }}
                    >
                      {voice?.label ?? t('content.voice')}
                    </Button>
                    <Picker
                      columns={voiceData}
                      visible={visibleVoide}
                      onClose={() => {
                        setVisibleVoide(false);
                      }}
                      onConfirm={(_value, data) => {
                        console.log(data);

                        setVoice(data.items?.[0]);
                      }}
                      confirmText='OK'
                      cancelText='Cancel'
                    />
                  </>
                ) : (
                  <>
                    <Dropdown ref={refLang} className='dropdown-container'>
                      <Dropdown.Item
                        key='bizop'
                        title={lang?.label ?? t('content.language')}
                      >
                        <div
                          style={{
                            padding: 12,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {langData[0].map((item, index) => {
                            return (
                              <div
                                className='px-6 py-3'
                                style={{
                                  marginBottom: '12px',
                                  cursor: 'pointer',
                                }}
                                key={index}
                                onClick={() => {
                                  refLang?.current?.close();
                                  onSelectLangToGetVoices(item);
                                }}
                              >
                                {item.label}
                              </div>
                            );
                          })}
                        </div>
                      </Dropdown.Item>
                    </Dropdown>
                    <Dropdown ref={ref} className='dropdown-container'>
                      <Dropdown.Item
                        key='sorter'
                        title={voice?.label ?? t('content.voice')}
                      >
                        {lang ? (
                          <div
                            style={{
                              padding: 12,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {voiceData[0].map((item, index) => {
                              return (
                                <div
                                  className='px-6 py-3'
                                  style={{
                                    marginBottom: '12px',
                                    cursor: 'pointer',
                                  }}
                                  key={index}
                                  onClick={() => {
                                    ref?.current?.close();
                                    setVoice(item);
                                  }}
                                >
                                  {item.label}
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div
                            style={{
                              padding: 12,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          ></div>
                        )}
                      </Dropdown.Item>
                    </Dropdown>
                  </>
                )}
              </div>
            </div>

            <div>
              <Button
                onClick={() => onSubmit({ voice, lang, textChange })}
                disabled={disableButton}
              >
                {t('generate')}
              </Button>
            </div>
          </div>

          <div className='audio-content'>
            {srcAudio ? (
              <>
                <AudioPlayer src={srcFile} />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Button
                    className='audio-download'
                    size='mini'
                    onClick={downloadAudio}
                  >
                    {t('downloadAudio')}
                  </Button>
                </div>
              </>
            ) : (
              <>
                {!isLoading ? (
                  <>
                    {t('resultText')}
                  </>
                ) : (
                  <SpinLoading />
                )}
              </>
            )}
          </div>
        </div>
      }
    />
  );
}

export default App;
