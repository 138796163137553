export async function digiforceTextToSpeech(data, token) {
  try {
    const res = await fetch(`https://miniapp-proxy.mojo.vn/api/v4/digiforce_tts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      body: JSON.stringify({
        lang: data.lang,
        gender: data.gender,
        voice: data.voice,
        speed: 1,
        text: data.text,
        status: 'new',
      }),
      redirect: 'follow',
    }).then((res) => res.json());

    return res.value[0] ?? [];
  } catch (error) {
    throw new Error('Sorry, please try later!', error);
  }
}

export async function getDigiforceTextToSpeech(id, token) {
  try {
    const res = await fetch(`https://miniapp-proxy.mojo.vn/api/v4/digiforce_tts/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      redirect: 'follow',
    }).then((res) => res.json());
    return res;
  } catch (error) {
    throw new Error('Sorry, please try later!', error);
  }
}
